const isOffer = async (product) => {
  if (product?.listing_stock_amount !== 0 && product?.listing_sale_endDate !== null) {
    if (new Date(product?.listing_sale_endDate) >= new Date() && new Date(product?.listing_sale_startDate) <= new Date()) {
      return true;
    }
  }
  return false;
};

export const currentProductPrice = async (product) => {
  // Check if the product is on Offer
  let offerPrice = null;
  if (await isOffer(product)) {
    if (product?.listing_sale_price) {
      offerPrice = product?.listing_sale_price;
    } else {
      offerPrice = product?.listing_price - (product?.listing_price * product?.listing_sale_percent) / 100;
    }
  }

  // Check if the product is on Sooper Deal
  const isSooperDeal =
    product?.sooperdeal_price &&
    product?.sooperdeal_stock_amount !== 0 &&
    product?.sooperdeal_start_date &&
    product?.sooperdeal_expiration_date &&
    new Date(product.sooperdeal_start_date) <= new Date() && // Ensure we are after the start date
    new Date(product.sooperdeal_expiration_date) >= new Date(); // Ensure we are before the expiration date

  let sooperdealPrice = null;
  if (isSooperDeal) {
    sooperdealPrice = product?.sooperdeal_price;
  }

  // Final price is by importance: 1. sooperdeal_price 2. offerPrice 3. listing_price
  const finalPrice = sooperdealPrice || offerPrice || product?.listing_price || 0;
  const priceType = sooperdealPrice ? "sooperdeal_price" : offerPrice ? "offer_price" : "listing_price";

  if (finalPrice === 0) {
    return {
      finalPrice: 0,
      priceType: "listing_price",
      listing_price: 0,
    };
  } else {
    return {
      finalPrice: finalPrice ? parseFloat(finalPrice).toFixed(2) : 0,
      priceType: priceType,
      listing_price: product?.listing_price ? product?.listing_price.toFixed(2) : 0,
    };
  }
};
