import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { GridLoader } from "react-spinners";

const FeaturedCarousel = ({ featuredProducts }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slideInterval = useRef(null);

  const containerRef = useRef(null);
  const navigate = useNavigate();

  // Start auto-slide on mount
  useEffect(() => {
    if (featuredProducts.length > 1) {
      slideInterval.current = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % featuredProducts.length);
      }, 5000); // 5-second interval
    }

    return () => clearInterval(slideInterval.current); // Clean up
  }, [featuredProducts]);

  // Scroll to current image
  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        left: currentIndex * containerRef.current.offsetWidth * 0.85, // 85% width to show next image
        behavior: "smooth",
      });
    }
  }, [currentIndex]);

  if (featuredProducts.length === 0) return null;

  return (
    <>
      <div style={{ padding: "15px 0px 0px 10px", fontSize: 12, fontWeight: 600 }}>Featured</div>
      <div
        ref={containerRef}
        style={{
          display: "flex",
          overflowX: "auto",
          scrollBehavior: "smooth",
          scrollSnapType: "x mandatory",
          padding: "5px 10px 15px 10px",
          gap: "10px",
          maxWidth: "100%",
          scrollbarWidth: "none",
        }}
      >
        {featuredProducts.map((product, index) => (
          <div
            onClick={() => {
              if (product.featured_url.startsWith("http://") || product.featured_url.startsWith("https://")) {
                window.location.href = product.featured_url; // Navigate to external URL
              } else {
                navigate(product.featured_url); // Navigate internally
              }
            }}
            key={product.id}
            href={product.link}
            style={{
              flex: featuredProducts.length === 1 ? "0 0 100%" : "0 0 90%", // Full width if only one element

              scrollSnapAlign: "center",
              position: "relative",
              overflow: "hidden",
              borderRadius: "12px",
              maxHeight: "190px",
              maxWidth: "340px",
              border: "1px solid #e0e0e0",
              background: "#fafafa",
            }}
          >
            <img
              src={product.image_url || "https://placehold.co/800x450"}
              alt={product.title}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                aspectRatio: "800/450",
                zIndex: 10,
                position: "relative",
              }}
            />
            <div
              style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                top: 0,
                color: "white",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                zIndex: 0,
                opacity: 0.1,
              }}
            >
              <GridLoader />
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

export default FeaturedCarousel;
