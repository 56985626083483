import React, { useState, useEffect, useRef, useContext } from "react";
import "../styles/global.css"; // Ensure path is correct
import { ExpandMore, ExpandLess } from "@material-ui/icons";
import Cookies from "js-cookie";
import { EnvContext } from "../context/EnvContext";
import { logAnalyticsEvent } from "../firebase/firebaseConfig";

const SearchFilters = ({ onApplyFilters, openFilter, setOpenFilter }) => {
  //
  const environment = useContext(EnvContext);
  //
  const [selectedCategories, setSelectedCategories] = useState([]); // Tracks selected categories
  const [selectedTypes, setSelectedTypes] = useState([]); // Tracks selected types
  const [selectedStrainTypes, setSelectedStrainTypes] = useState([]); // Tracks selected strain types

  const [isOnSale, setIsOnSale] = useState(false); // Tracks on sale toggle
  const [priceOrder, setPriceOrder] = useState(""); // Tracks price order filter
  const [inStockOnly, setInStockOnly] = useState(false); // Tracks in stock filter
  const [initialLoad, setInitialLoad] = useState(true); // Tracks whether the filters are being populated from cookies for the first time

  const [appliedFilters, setAppliedFilters] = useState({
    categories: [],
    types: [],
    strain_types: [],
    isOnSale: false,
    priceOrder: "",
    inStockOnly: false,
  });

  const filterRef = useRef(null);

  const categories = [
    { label: "🌿", value: "Herb", product_type: ["Plant"] },
    { label: "🍄", value: "Myco", product_type: ["Magic"] },
    { label: "🌸", value: "Flower", product_category: ["Flower"] },
    { label: "👌", value: "Preroll", product_category: ["Preroll"] },
    { label: "💨", value: "Vape", product_category: ["Dispo", "Carts", "Pods", "Battery"] },
    { label: "🍫", value: "Chocolate", product_category: ["Chocolate"] },
    { label: "🍬", value: "Gummy", product_category: ["Gummy"] },
    { label: "👜", value: "Accessory", product_category: ["Accessory"] },
    { label: "💰", value: "Deals" }, // "Deals" remains as a toggle filter
  ];

  const types = ["Plant", "Magic"];
  const strain_types = ["Indica", "Sativa", "Hybrid", "I-Hybrid", "S-Hybrid", "Other"];

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpenFilter(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  const applyFilters = (
    updatedCategories = selectedCategories,
    updatedIsOnSale = isOnSale,
    updatedPriceOrder = priceOrder,
    updatedInStockOnly = inStockOnly,
    updatedStrainTypes = selectedStrainTypes
  ) => {
    const filters = {
      product_category: [],
      product_type: [],
      categories: updatedCategories,
    };

    // Build `product_category` dynamically based on selected categories
    if (updatedCategories.length > 0) {
      updatedCategories.forEach((categoryValue) => {
        const category = categories.find((cat) => cat.value === categoryValue);
        if (category?.product_category) {
          filters.product_category = [...new Set([...filters.product_category, ...category.product_category])];
        }
        if (category?.product_type) {
          filters.product_type = [...new Set([...filters.product_type, ...category.product_type])];
        }
      });
    }

    // Include other filters
    const finalFilters = {
      ...filters,
      isOnSale: updatedIsOnSale,
      priceOrder: updatedPriceOrder,
      inStockOnly: updatedInStockOnly,
      strain_types: updatedStrainTypes,
    };

    // Debug: Check the structure of finalFilters
    console.log("Final Filters:", finalFilters);

    // Ensure finalFilters is serializable
    try {
      const jsonString = JSON.stringify(finalFilters); // Test serialization
      Cookies.set("filterPreferences", jsonString, { expires: 1 }); // Persist filters in cookies
    } catch (error) {
      console.error("Error serializing filters:", error);
    }

    // Update applied filters state and trigger callback
    setAppliedFilters(finalFilters);
    onApplyFilters(finalFilters);

    // Log analytics event
    logAnalyticsEvent("apply_filter_button", {
      applied_filters: finalFilters,
      environment: environment,
    });
  };

  useEffect(() => {
    if (initialLoad) {
      const savedFilters = Cookies.get("filterPreferences");
      if (savedFilters) {
        const parsedFilters = JSON.parse(savedFilters);

        // Update state with filters loaded from cookies
        setSelectedCategories(parsedFilters.categories || []);
        setIsOnSale(parsedFilters.isOnSale || false);
        setPriceOrder(parsedFilters.priceOrder || "");
        setInStockOnly(parsedFilters.inStockOnly || false);
        setSelectedStrainTypes(parsedFilters.strain_types || []);

        // Delay `applyFilters` until all states are updated
        setTimeout(() => {
          applyFilters(
            parsedFilters.categories || [],
            parsedFilters.isOnSale || false,
            parsedFilters.priceOrder || "",
            parsedFilters.inStockOnly || false,
            parsedFilters.strain_types || []
          );
        }, 0);
      }
      setInitialLoad(false); // Mark initial load as complete
    }
  }, [initialLoad]);

  const handleApplyFiltersClick = () => {
    applyFilters();
    setOpenFilter(null); // Close the modal explicitly when the user clicks "Apply Filters"

    // Log analytics event
    logAnalyticsEvent("apply_filter_button", {
      environment: environment,
    });
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (filterRef.current && !filterRef.current.contains(event.target)) {
        setOpenFilter(null); // Close filter if click happens outside
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [filterRef]);

  const toggleCategory = (categoryValue) => {
    const category = categories.find((cat) => cat.value === categoryValue);

    if (!category) return;

    setSelectedCategories((prevSelectedCategories) => {
      const updatedCategories = prevSelectedCategories.includes(categoryValue)
        ? prevSelectedCategories.filter((item) => item !== categoryValue)
        : [...prevSelectedCategories, categoryValue];

      // Update filters dynamically
      applyFilters(updatedCategories, isOnSale, priceOrder, inStockOnly, selectedStrainTypes);

      return updatedCategories;
    });

    if (category.product_type) {
      setSelectedTypes((prevSelectedTypes) => {
        const updatedTypes = category.product_type.filter((type) => !prevSelectedTypes.includes(type));

        return [...prevSelectedTypes, ...updatedTypes];
      });
    }
  };

  const toggleStrainType = (strainType) => {
    setSelectedStrainTypes((prev) => {
      const updatedStrainTypes = prev.includes(strainType) ? prev.filter((item) => item !== strainType) : [...prev, strainType];

      // Apply filters while retaining other filter states
      const updatedFilters = {
        categories: selectedCategories, // Retain current selected categories
        isOnSale, // Retain current isOnSale value
        priceOrder, // Retain current priceOrder value
        inStockOnly, // Retain current inStockOnly value
        strain_types: updatedStrainTypes, // Updated strain types
      };

      applyFilters(updatedFilters.categories, updatedFilters.isOnSale, updatedFilters.priceOrder, updatedFilters.inStockOnly, updatedFilters.strain_types);

      // Store filters in cookies
      Cookies.set("filterPreferences", JSON.stringify(updatedFilters), { expires: 1 });

      return updatedStrainTypes;
    });
  };

  const toggleOnSale = () => {
    setIsOnSale((prev) => {
      const updatedIsOnSale = !prev;

      // Apply filters while retaining other filter states
      const updatedFilters = {
        categories: selectedCategories, // Retain current selected categories
        isOnSale: updatedIsOnSale, // Updated isOnSale value
        priceOrder, // Retain current priceOrder value
        inStockOnly, // Retain current inStockOnly value
        strain_types: selectedStrainTypes, // Retain current strain types
      };

      applyFilters(updatedFilters.categories, updatedFilters.isOnSale, updatedFilters.priceOrder, updatedFilters.inStockOnly, updatedFilters.strain_types);

      // Store filters in cookies
      Cookies.set("filterPreferences", JSON.stringify(updatedFilters), { expires: 1 });

      return updatedIsOnSale;
    });
  };

  const togglePriceOrder = (order) => {
    const newOrder = order === priceOrder ? "" : order;
    setPriceOrder(newOrder);

    // Apply filters while retaining other filter states
    applyFilters(
      selectedCategories, // Retain current selected categories
      isOnSale, // Retain current isOnSale value
      newOrder, // Updated price order
      inStockOnly, // Retain current inStockOnly value
      selectedStrainTypes // Retain current strain types
    );
  };

  const toggleInStock = () => {
    setInStockOnly((prev) => {
      const updatedInStockOnly = !prev;

      // Apply filters while retaining other filter states
      const updatedFilters = {
        categories: selectedCategories, // Retain current selected categories
        isOnSale, // Retain current isOnSale value
        priceOrder, // Retain current priceOrder value
        inStockOnly: updatedInStockOnly, // Updated inStockOnly value
        strain_types: selectedStrainTypes, // Retain current strain types
      };

      applyFilters(updatedFilters.categories, updatedFilters.isOnSale, updatedFilters.priceOrder, updatedFilters.inStockOnly, updatedFilters.strain_types);

      // Store filters in cookies
      Cookies.set("filterPreferences", JSON.stringify(updatedFilters), { expires: 1 });

      return updatedInStockOnly;
    });
  };

  const clearOtherFilters = () => {
    setPriceOrder("");
    setInStockOnly(false);
    setSelectedStrainTypes([]);
    // setSelectedCategories([]);
    setIsOnSale(false);

    setOpenFilter(null);

    // Apply filters while retaining selected categories and isOnSale state
    const updatedFilters = {
      // categories: [],
      isOnSale: false, // Retain current isOnSale value
      priceOrder: "", // Reset priceOrder
      inStockOnly: false, // Reset inStockOnly
      strain_types: [], // Clear strain types
    };

    applyFilters(updatedFilters.categories, false, updatedFilters.priceOrder, updatedFilters.inStockOnly, updatedFilters.strain_types);

    // Store updated filters in cookies
    Cookies.set("filterPreferences", JSON.stringify(updatedFilters), { expires: 1 });

    //
    //
  };

  return (
    <>
      {openFilter && <div style={styles.overlay} onClick={() => setOpenFilter(null)} />}

      <div ref={filterRef} style={{ zIndex: 1000, position: "relative" }}>
        <div style={styles.filterContainer}>
          {/* Filter Buttons */}

          <div style={styles.categoriesContainer}>
            {categories.map((category) => (
              <div
                key={category.value}
                onClick={() => {
                  if (category.value === "Deals") {
                    // Apply the same logic as the dropdown filter for Deals
                    setIsOnSale((prev) => {
                      const updatedIsOnSale = !prev;
                      applyFilters(
                        selectedCategories, // Retain current selected categories
                        updatedIsOnSale, // Toggle isOnSale state
                        priceOrder, // Retain current price order
                        inStockOnly, // Retain current inStockOnly value
                        selectedStrainTypes // Retain current strain types
                      );

                      // Store filters in cookies
                      const updatedFilters = {
                        categories: selectedCategories,
                        isOnSale: updatedIsOnSale,
                        priceOrder,
                        inStockOnly,
                        strain_types: selectedStrainTypes,
                      };
                      Cookies.set("filterPreferences", JSON.stringify(updatedFilters), { expires: 1 });

                      logAnalyticsEvent("filter_selection", {
                        type: "deals",
                        environment: environment,
                      });

                      return updatedIsOnSale;
                    });
                  } else {
                    toggleCategory(category.value); // For all other categories, use the standard toggle logic
                  }
                }}
                style={{
                  ...styles.categoryInlineItem,
                }}
              >
                <div
                  style={{
                    ...styles.categoryLabel,
                    ...(category.value === "Deals" && isOnSale ? styles.categoryLableSelected : {}),
                    ...(selectedCategories.includes(category.value) ? styles.categoryLableSelected : {}),
                  }}
                >
                  {category.label}
                </div>
                <div
                  style={{
                    ...styles.categoryName,
                    ...(category.value === "Deals" && isOnSale ? styles.categoryNameSelected : {}),
                    ...(selectedCategories.includes(category.value) ? styles.categoryNameSelected : {}),
                  }}
                >
                  {category.value}
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Other Filters Panel */}
        <div
          style={{
            ...styles.filterPanel,
            maxHeight: openFilter === "other-filters" ? "540px" : "0",
          }}
        >
          <div style={styles.filterContent}>
            <div style={{ fontSize: ".8rem", marginBottom: "10px" }}>Sort By Price</div>
            <button
              onClick={() => togglePriceOrder("low-to-high")}
              style={{
                ...styles.categoryButton,
                ...(priceOrder === "low-to-high" ? styles.selectedCategory : {}),
                marginRight: "15px",
              }}
            >
              Price: <b>Low to High</b>
            </button>
            <button
              onClick={() => togglePriceOrder("high-to-low")}
              style={{
                ...styles.categoryButton,
                ...(priceOrder === "high-to-low" ? styles.selectedCategory : {}),
              }}
            >
              Price: <b>High to Low</b>
            </button>
            <div
              style={{
                fontSize: ".8rem",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            >
              In Stock Only
            </div>
            <button
              onClick={toggleInStock}
              style={{
                ...styles.categoryButton,
                ...(inStockOnly ? styles.selectedCategory : {}),
              }}
            >
              In Stock Only
            </button>

            {/* Strain Types Filter */}
            <div
              style={{
                fontSize: ".8rem",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            >
              Strain Types
            </div>
            <div style={styles.categoriesContainer}>
              {strain_types.map((strainType) => (
                <button
                  key={strainType}
                  onClick={() => toggleStrainType(strainType)}
                  style={{
                    ...styles.categoryButton,
                    ...(selectedStrainTypes.includes(strainType) ? styles.selectedCategory : {}),
                  }}
                >
                  {strainType}
                  {selectedStrainTypes.includes(strainType) && <span style={styles.closeIcon}> &times;</span>}
                </button>
              ))}
            </div>
            {/* Deals */}
            <div
              style={{
                fontSize: ".8rem",
                marginBottom: "10px",
                marginTop: "20px",
              }}
            >
              Deals
            </div>
            <button
              onClick={toggleOnSale} // Toggle the Deals filter and apply filters
              style={{
                ...styles.categoryButton,
                ...(isOnSale ? styles.selectedCategory : {}),
              }}
            >
              Deals
            </button>
          </div>
          {/* Action buttons */}
          <div style={styles.actionButtonsContainer}>
            <button
              style={{
                ...styles.actionButton,
                borderRight: "1px solid #ddd",
              }}
              onClick={clearOtherFilters}
            >
              Clear filters
            </button>
            <button style={styles.actionButton} onClick={handleApplyFiltersClick}>
              Apply filters
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

// Styles for SearchFilters component (unchanged)
const styles = {
  filterContainer: {
    position: "relative",
    backgroundColor: "white",
    alignItems: "center",
    display: "flex",
    zIndex: 1000,
    height: 65, // Prevent overflowing horizontally
    overflowX: "hidden", // Enable horizontal scrolling
  },
  buttonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    borderBottom: "1px solid #ddd",
    backgroundColor: "white",
  },
  filterButton: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    border: "none",
    height: "45px",
    cursor: "pointer",
    fontSize: "14px",
    borderTop: ".2px solid #808080",
    zIndex: 1000,
  },
  filterPanel: {
    position: "absolute",
    top: "65px",
    left: 0,
    right: 0,
    overflow: "hidden",
    transition: "max-height 0.3s ease",
    zIndex: 1,
    backgroundColor: "#fff",
    boxShadow: "0px 14px 15px rgba(0, 0, 0, 0.1)",
    left: 0,
    right: 0,
  },
  filterContent: {
    padding: "20px",
  },
  categoriesContainer: {
    display: "flex",
    alignItems: "center",
    overflowX: "auto", // Enable horizontal scrolling
    whiteSpace: "nowrap", // Prevent wrapping of items
    gap: "10px", // Add space between items
    padding: "10px 0",
    scrollbarWidth: "thin", // Optional: For Firefox
    msOverflowStyle: "none", // Optional: For Internet Explorer/Edge
    WebkitOverflowScrolling: "touch", // Smooth scrolling on mobile
  },
  categoryButton: {
    border: "1px solid #ddd",
    padding: "8px 12px",
    cursor: "pointer",
    fontSize: "14px",
    backgroundColor: "#fff",
    borderRadius: "5px",
  },
  selectedCategory: {
    backgroundColor: "#000",
    color: "#fff",
  },
  closeIcon: {
    marginLeft: "8px",
    fontSize: "12px",
  },
  actionButtonsContainer: {
    display: "flex",
    justifyContent: "space-between",
    borderTop: "1px solid #ddd",
  },
  actionButton: {
    flex: 1,
    padding: "20px",
    fontSize: "14px",
    cursor: "pointer",
    backgroundColor: "#f4f4f4",
    border: "none",
    textAlign: "center",
  },
  overlay: {
    position: "fixed",
    top: 130,
    left: 0,
    width: "100%",
    height: "100%",
    //backgroundColor: "rgba(0, 0, 0, 0.4)",
    zIndex: 999,
  },
  //
  categoryInlineItem: {
    cursor: "pointer",
    textAlign: "center",
    flex: "0 0 auto", // Prevent items from shrinking
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "60px", // Ensure consistent size
    marginTop: 2,
  },
  categoryName: {
    fontSize: "11px",
  },
  categoryNameSelected: {
    fontWeight: 600,
  },
  categoryLabel: {
    backgroundColor: "#eee",
    borderRadius: "50%",
    width: 42,
    height: 42,
    fontSize: "14px",
    border: "2px solid #eee",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    fontSize: "28px",
  },
  categoryLableSelected: {
    fontWeight: 500,
    border: "2px solid red",
  },
};

export default SearchFilters;
