import React, { useEffect, useState, useContext } from "react";
import Cookies from "js-cookie";
import { getFirestore, addDoc, collection, query, where, getDocs } from "firebase/firestore";
import Button from "@mui/material/Button";
import { EnvContext } from "../../context/EnvContext";
import config from "../../config";
import addToKlaviyo from "../../functions/addToKlaviyo";
import axios from "axios";

const NewsletterForm = () => {
  //
  const environment = useContext(EnvContext);
  //
  const [showNewsletterModal, setShowNewsletterModal] = useState(false);
  const [newsletterAccepted, setNewsletterAccepted] = useState(false);
  const [emailInput, setEmailInput] = useState("");
  const [signupComplete, setSignupComplete] = useState(false); // New state for signup completion

  // environment
  const collectionPath = environment === "staging" ? config.enveironment.staging.collectionPath : config.enveironment.production.collectionPath;

  // scroll trigger for newsletter modal
  useEffect(() => {
    const handleScroll = () => {
      const newsletterResponded = Cookies.get("newsletterResponded");
      if (!newsletterResponded && window.scrollY >= window.innerHeight) {
        setShowNewsletterModal(true);
        window.removeEventListener("scroll", handleScroll); // Prevent multiple triggers
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll); // Cleanup on unmount
    };
  }, []);

  // time trigger for newsletter modal
  /*
  useEffect(() => {
    const newsletterResponded = Cookies.get("newsletterResponded");
    if (!newsletterResponded) {
      setTimeout(() => {
        setShowNewsletterModal(true);
      }, 60000);
    }
  }, []);
  */

  //
  //
  //
  const createWelcomeEmail = async (email) => {
    const firestore = getFirestore();

    const message = {
      html: `
      <!DOCTYPE html>
      <html>
      <head>
        <meta charset="UTF-8">
        <title>Welcome to the Club!</title>
        <style>
          body {
            font-family: Arial, sans-serif;
            margin: 20px;
          }
          h1 {
            color: #333;
          }
        </style>
      </head>
      <body>
        <h1>Welcome to the club!</h1>
        <p>We’ll send your 15% off code to the email address you provided. Now go find that vibe you’ve been looking for!</p>
      </body>
      </html>
    `,
      text: `
      Welcome to the club!

      We’ll send your 15% off code to the email address you provided. Now go find that vibe you’ve been looking for!
    `,
      subject: "Welcome to the club!",
      from: "welcome@sooperstock.com",
    };

    try {
      const docRef = await addDoc(collection(firestore, "Messages/Mail/DataBase"), { message, email, to: [email] });
      console.log("Welcome email message added successfully, ID:", docRef.id);
    } catch (error) {
      console.error("Error adding welcome email message to Firestore:", error);
    }
  };

  //
  //
  //

  const handleAcceptNewsletter = async () => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    if (!emailRegex.test(emailInput) || emailInput.length < 5 || !emailInput.includes("@") || !emailInput) {
      alert("Please enter a valid email address");
      return;
    }

    const firestore = getFirestore();

    try {
      // Check if the email already exists in the database
      const existingEmailQuery = query(collection(firestore, `${collectionPath}Newsletter/Subscribers/DataBase`), where("email", "==", emailInput));
      const querySnapshot = await getDocs(existingEmailQuery);

      if (!querySnapshot.empty) {
        alert("This email is already subscribed.");
        setShowNewsletterModal(false);
        return;
      }

      const payload = {
        id: "TQxfBz",
        email: emailInput,
      };

      const response = await axios.post(`${config.apiConfig.API_ENDPOINT_URL}/helper/addToKlaviyo`, payload, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      //   addToKlaviyo("TQxfBz", emailInput); // Add the user to the Klaviyo list
      // Add the email to the newsletter collection

      /*
      await addDoc(collection(firestore, `${collectionPath}Newsletter/Subscribers/DataBase`), {
        email: emailInput,
        subscribedAt: new Date(),
      });
      */

      // Call the function to create a welcome email message
      await createWelcomeEmail(emailInput);

      // Set the newsletter responded cookie and close the modal
      Cookies.set("newsletterResponded", true, { expires: 365 });
      setNewsletterAccepted(true);
      setSignupComplete(true); // Mark signup as complete
    } catch (error) {
      console.error("Error signing up for newsletter:", error);
      alert("Failed to sign up for the newsletter.");
    }
  };

  const handleDeclineNewsletter = () => {
    Cookies.set("newsletterResponded", true, { expires: 1 });
    setShowNewsletterModal(false);
  };

  if (!showNewsletterModal || newsletterAccepted) return null;

  return (
    <div
      style={{
        display: "flex",
        padding: 30,
        position: "fixed",
        top: "0",
        left: "0",
        width: "100vw",
        height: "100vh",
        zIndex: "1000",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        // blur the background
        backdropFilter: "blur(2px)",
        WebkitBackdropFilter: "blur(2px)",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div style={{ ...styles.modal, flexDirection: "column", padding: "20px" }}>
        <div
          style={{
            ...styles.modalContent,
            width: "100%",
            padding: "5px",
            justifyContent: "flex-end",
            display: "flex",
          }}
        >
          <div
            onClick={handleDeclineNewsletter}
            style={{
              display: "flex",
              padding: "10px 20px",
              fontSize: "1.7rem",
              cursor: "pointer",
            }}
          >
            &times;
          </div>
        </div>
        <div style={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img src="/images/sooperstock_logo.svg" alt="Sooperstock" style={{ height: "55px" }} />
          </div>
          <div
            style={{
              display: "flex",
              flex: 1,
              alignItems: "flex-start",
              textAlign: "center",
            }}
          >
            <div>
              <h2 style={{ paddingBottom: "10px" }}>{signupComplete ? "Welcome to the club!" : "GET 15% OFF!"}</h2>
              <p>
                {signupComplete
                  ? "We’ll send your 15% off code to the email address you provided. Now go find that vibe you’ve been looking for!"
                  : "It’s like free money just dropping into your inbox. We’ll also let you in on the hottest new drops and exclusive deals."}
              </p>
              {!signupComplete && (
                <>
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={emailInput}
                    onChange={(e) => setEmailInput(e.target.value)}
                    style={{ ...styles.modalInput, width: "100%" }}
                  />
                  <div style={styles.modalButtons}>
                    <Button onClick={handleAcceptNewsletter} style={{ ...styles.modalButton, width: "100%" }}>
                      Continue
                    </Button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  modal: {
    width: "100%",
    backgroundColor: "white",
    zIndex: 1001,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    textAlign: "center",
  },
  modalButtons: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "space-around",
  },
  modalButton: {
    backgroundColor: "#000",
    color: "#fff",
    padding: "10px 20px",
    borderRadius: "5px",
    cursor: "pointer",
  },
  modalInput: {
    padding: "10px",
    width: "80%",
    marginTop: "20px",
    border: "1px solid #ccc",
    borderRadius: "5px",
  },
};

export default NewsletterForm;
